import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Heading from '../components/heading'
import Highlight1 from '../components/highlight1'
import Feature from '../components/feature'
import Quote from '../components/quote'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Oshe Immo</title>
        <meta
          name="description"
          content="Trouvez Votre Prochaine propriété avec Simplicité"
        />
        <meta property="og:title" content="Oshe Immo" />
        <meta
          property="og:description"
          content="Trouvez Votre Prochaine propriété avec Simplicité"
        />
      </Helmet>
      <div className="home-header">
        <Heading></Heading>
        <div className="home-hero">
          <div className="home-content">
            <h1 className="home-title">
              Trouvez Votre Prochaine propriété avec Simplicité
            </h1>
            <span className="home-caption">
              Découvrez une expérience immobilière unique, de la première visite
              à la transaction sécurisée
            </span>
            <div className="home-hero-buttons">
              <div className="home-ios-btn">
                <img
                  alt="pastedImage"
                  src="/pastedimage-zmzg.svg"
                  className="home-apple"
                />
                <span className="home-caption1">
                  Disponible sur l&apos;App Store
                </span>
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=com.ohseimmo.client"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link"
              >
                <div className="home-android-btn">
                  <img
                    alt="pastedImage"
                    src="/pastedimage-ld65.svg"
                    className="home-android"
                  />
                  <span className="home-caption2">
                    Téléchargez sur PlayStore
                  </span>
                </div>
              </a>
            </div>
          </div>
          <div className="home-images">
            <div className="home-column">
              <img
                alt="pastedImage"
                src="/static/mobile-app/detail-1500h.jpg"
                className="home-pasted-image"
              />
            </div>
            <div className="home-column1">
              <img
                alt="pastedImage"
                src="/static/mobile-app/home-1500h.jpg"
                className="home-pasted-image1"
              />
              <img
                alt="pastedImage"
                src="/static/mobile-app/unnamed%20(2)-1500h.webp"
                className="home-pasted-image2"
              />
            </div>
            <div className="home-column2">
              <img
                alt="pastedImage"
                src="/static/mobile-app/visit-1500h.jpg"
                className="home-pasted-image3"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="discover" className="home-video">
        <div className="home-content01">
          <div className="home-header2">
            <h2 className="home-text">
              Découvrez Oshe Immo une agence immobilière très fiable, plus
              rapide, et plus efficace
            </h2>
          </div>
          <div className="home-video-container">
            <video
              src="https://www.youtube.com/watch?v=MRQ69XeDxVk"
              loop
              muted
              poster="/pastedimage-v2-900w.png"
              autoPlay
              className="home-video1"
            ></video>
            <div className="home-heading-container">
              <div className="home-heading">
                <span className="home-text01">
                  Oshe Immo, bien plus qu&apos;une agence immobilière -
                  c&apos;est une promesse de transactions rapides et efficaces.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text02">
                  <span>
                    Construit spécifiquement pour ceux qui cherchent à
                    simplifier leur parcours immobilier, notre plateforme vous
                    offre bien plus qu&apos;une simple recherche de biens.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    {' '}
                    Avec sa vision axée sur la facilitation des transactions
                    immobilières et de l&apos;aménagement, OSHE IMMO
                    s&apos;affirme comme une solution incontournable pour tous
                    les besoins immobiliers
                  </span>
                </span>
              </div>
              <div className="home-explore"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="app-features" className="home-sections">
        <div className="home-section">
          <div className="home-image"></div>
          <div className="home-content02">
            <h2 className="home-text07">
              Découvrez Notre Sélection de Biens Immobiliers
            </h2>
            <Highlight1
              Title="Parcourez notre liste complète de biens immobiliers."
              Description=" Chaque propriété est présentée avec des détails complets pour vous aider à  faire un choix répondant à vos besoins"
            ></Highlight1>
          </div>
        </div>
        <div className="home-section1">
          <div className="home-content03">
            <div className="home-heading1">
              <h2 className="home-text08">Chaque Détail Compte</h2>
            </div>
            <Highlight1
              Title="Explorez les détails minutieux de chaque bien. "
              Description="Des informations cruciales sur la taille, le nombre de chambres, les commodités et bien plus encore. Nous croyons que chaque détail compte dans votre recherche immobilière"
            ></Highlight1>
          </div>
          <div className="home-image1"></div>
        </div>
        <div className="home-section2">
          <div className="home-image2"></div>
          <div className="home-content04">
            <h2 className="home-text09">
              Programmez des Visites Guidées avec Notre Équipe Expert
            </h2>
            <Highlight1
              Title="Vous avez trouvé un bien qui vous plaît?"
              Description="Programmez une visite guidée avec nos experts immobiliers. Nous vous accompagnons à chaque étape pour vous assurer une expérience de visite personnalisée et informative"
            ></Highlight1>
          </div>
        </div>
        <div className="home-section3">
          <div className="home-content05">
            <div className="home-heading2">
              <h2 className="home-text10">
                Gardez une Trace de Vos Coups de Cœur
              </h2>
            </div>
            <Highlight1
              Title="Sauvegardez vos biens préférés pour un accès facile."
              Description="Partagez-les avec vos proches pour recueillir leurs avis. Chez Oshimo Immobilier, nous facilitons la gestion de vos choix immobiliers"
            ></Highlight1>
            <div className="home-content06"></div>
          </div>
          <div className="home-image3"></div>
        </div>
      </div>
      <div className="home-banner-container">
        <div className="home-banner">
          <div className="home-overlay">
            <span className="home-text11">
              Initiez votre parcours immobilier avec Oshe Immo
            </span>
            <div className="home-book-btn">
              <span className="home-text12">Contactez nous</span>
            </div>
          </div>
          <img
            alt="pastedImage"
            src="/pastedimage-ylke.svg"
            className="home-pasted-image4"
          />
        </div>
      </div>
      <div className="home-features">
        <div className="home-header3">
          <div className="home-heading3">
            <h2 className="home-text13">
              Tout ce que vous obtenez avec Oshe Immo
            </h2>
          </div>
        </div>
        <div className="home-feature-list">
          <Feature
            Title="Visibilité Optimale"
            Description="Des stratégies dynamiques, de Facebook à TikTok, assurent une visibilité optimale en ligne. Sur le terrain, nos équipes locales promeuvent activement vos biens, renforçant ainsi leur présence dans votre quartier"
          ></Feature>
          <Feature
            Title="Multiple Devices"
            Thumbnail="/vector6113-r6dl.svg"
          ></Feature>
          <Feature Title="Analytics" Thumbnail="/vector6113-6zj.svg"></Feature>
          <Feature
            Title="Virtual Card"
            Thumbnail="/vector6113-lvvs.svg"
          ></Feature>
          <Feature
            Title="Safe Transactions"
            Thumbnail="/vector6114-cupp.svg"
          ></Feature>
          <Feature
            Title="Milestones"
            Thumbnail="/vector6114-6m1e.svg"
          ></Feature>
          <Feature Title="Trade" Thumbnail="/vector6114-yjl.svg"></Feature>
          <Feature Title="Wallet" Thumbnail="/vector6113-lvvs.svg"></Feature>
        </div>
      </div>
      <div className="home-banner1">
        <h2 className="home-text14">
          <span>Initiez votre parcours immobilier avec Oshe Immo</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br className="home-text17"></br>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </h2>
        <Link to="/contact" className="home-navlink">
          <div className="home-book-btn1">
            <span className="home-text19">Contactez nous</span>
          </div>
        </Link>
      </div>
      <div className="home-testimonials">
        <div className="home-logo-container">
          <img
            alt="pastedImage"
            src="/pastedimage-idcu.svg"
            className="home-logo"
          />
        </div>
        <div className="home-content07">
          <div id="quotes" className="home-quotes">
            <div className="quote active-quote">
              <Quote
                Quote="&quot;En pleine transition vers une nouvelle ville, j'étais à la recherche d'un logement idéal. Oshe Immo a simplifié ma quête en me permettant de trouver rapidement un appartement répondant à toutes mes attentes, me permettant ainsi de me concentrer sereinement sur ma nouvelle opportunité professionnelle&quot;"
                rootClassName="quote-root-class-name"
              ></Quote>
            </div>
            <div className="quote">
              <Quote
                Quote="&quot;En tant qu'entrepreneur immobilier, la visibilité est clé. Oshe Immo m'a offert une plateforme pour booster la visibilité de mes biens. J'ai été impressionné par la rapidité des retours et la qualité des prospects. Des résultats qui parlent d'eux-mêmes!&quot;"
                Title="Developer @ Vista La Vista"
                Author="Author 2"
                rootClassName="quote-root-class-name"
              ></Quote>
            </div>
            <div className="quote">
              <Quote
                Quote="&quot;Déposer ma maison sur . Oshe Immo a été simple et rapide. J'ai été agréablement surprise par la facilité de gestion et la rapidité avec laquelle j'ai trouvé des acheteurs sérieux. Une expérience sans tracas et des résultats concrets.&quot;"
                Title="Designer @ OhBoy"
                rootClassName="quote-root-class-name"
              ></Quote>
            </div>
          </div>
          <div className="home-buttons">
            <div id="quote-before" className="home-left">
              <svg viewBox="0 0 1024 1024" className="home-icon">
                <path d="M854 470v84h-520l238 240-60 60-342-342 342-342 60 60-238 240h520z"></path>
              </svg>
            </div>
            <div id="quote-next" className="home-right">
              <svg viewBox="0 0 1024 1024" className="home-icon2">
                <path d="M512 170l342 342-342 342-60-60 238-240h-520v-84h520l-238-240z"></path>
              </svg>
            </div>
          </div>
          <div>
            <div className="home-container2">
              <Script
                html={` <script>
    /* Quote Slider - Code Embed */

    let current = 1;

    const nextButton = document.querySelector("#quote-next");
    const previousButton = document.querySelector("#quote-before");
    const quotes = document.querySelectorAll(".quote");

    if (nextButton && previousButton && quotes) {
      nextButton.addEventListener("click", () => {
        quotes.forEach((quote) => {
          quote.classList.remove("active-quote");
        });

        current == quotes.length ? (current = 1) : current++;
        quotes[current - 1].classList.add("active-quote");
      });

      previousButton.addEventListener("click", () => {
        quotes.forEach((quote) => {
          quote.classList.remove("active-quote");
        });

        current == 1 ? (current = quotes.length) : current--;
        quotes[current - 1].classList.add("active-quote");
      });
    }
  </script>`}
              ></Script>
            </div>
          </div>
        </div>
      </div>
      <div className="home-faq">
        <div className="home-content08">
          <div className="home-header4">
            <div className="home-tag">
              <span className="home-text20">
                <span>FAQ</span>
                <br></br>
              </span>
            </div>
            <div className="home-heading4">
              <h2 className="home-text23">Questions fréquemment posées</h2>
            </div>
          </div>
          <div className="home-rows">
            <div className="home-column3">
              <div className="Question">
                <span className="home-title1">
                  Quels sont les frais associés à la publication de mon bien sur
                  Oshe Immo?
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-description">
                  Chez Oshe Immo, publier votre bien est gratuit. Nos frais
                  interviennent seulement lors de la planification des visites,
                  car nous croyons en une approche flexible et transparente pour
                  votre bénéfice.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
              <div className="Question">
                <span className="home-title2">
                  Quels outils de filtrage sont disponibles pour faciliter ma
                  recherche de biens immobiliers?
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-description1">
                  <span>
                    Sur la plateforme Oshe Immo, nous mettons à votre
                    disposition une gamme complète d&apos;outils de filtrage
                    avancés. Notre objectif est de simplifier votre recherche en
                    vous permettant de trouver précisément ce que vous
                    recherchez.
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </div>
              <div className="home-question2 Question">
                <span className="home-title3">
                  Puis-je suivre l&apos;évolution de la publication de mon bien
                  sur la plateforme?
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-description2">
                  Votre satisfaction est primordiale chez Oshe Immo. Nous vous
                  offrons un accès complet pour suivre l&apos;évolution de votre
                  bien, car nous croyons en une transparence totale tout au long
                  du processus
                </span>
              </div>
            </div>
            <div className="home-column4">
              <div className="home-question3 Question">
                <span className="home-title4">
                  Comment puis-je garantir la sécurité de mes données
                  personnelles lors de l&apos;utilisation de la plateforme?
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-description3">
                  La sécurité de vos données est notre priorité chez Oshe Immo.
                  Nous utilisons des mesures de sécurité avancées pour garantir
                  que toutes vos informations personnelles sont traitées avec le
                  plus grand soin et conformément aux normes de sécurité
                  strictes.
                </span>
              </div>
              <div className="home-question4 Question">
                <span className="home-title5">
                  Comment se déroule le processus de programmation de visites
                  guidées avec Oshe Immo?
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-description4">
                  La planification des visites est simple et personnalisée chez
                  Oshe Immo. Vous sélectionnez le bien qui vous intéresse et
                  l&apos;horaire qui vous convient, nous prenons soin de tout le
                  reste pour vous assurer une expérience fluide et adaptée
                </span>
              </div>
              <div className="home-question5 Question">
                <span className="home-title6">
                  Quelle assistance est offerte aux utilisateurs en cas de
                  besoin?
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-description5">
                  <span>
                    Chez Oshe Immo, notre équipe est à votre disposition. Nous
                    sommes disponibles à tout moment pour répondre à vos
                    questions et vous assister dans vos démarches. Votre
                    confiance est notre priorité.
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-get-started">
        <div className="home-content09">
          <div className="home-heading5">
            <h2 className="home-text28">
              Commencez dès maintenant avec Oshe Immo !
            </h2>
          </div>
          <div className="home-hero-buttons1">
            <div className="home-ios-btn1">
              <img
                alt="pastedImage"
                src="/pastedimage-zmzg.svg"
                className="home-apple1"
              />
              <span className="home-caption3">
                Disponible sur l&apos;App Store
              </span>
            </div>
            <a
              href="https://play.google.com/store/apps/details?id=com.ohseimmo.client"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link1"
            >
              <div className="home-android-btn1">
                <img
                  alt="pastedImage"
                  src="/pastedimage-ld65.svg"
                  className="home-android1"
                />
                <span className="home-caption4">Téléchargez sur PlayStore</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Home
