import React from 'react'

import PropTypes from 'prop-types'

import Social from './social'
import './footer.css'

const Footer = (props) => {
  return (
    <div className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-content">
        <div className="footer-information">
          <div className="footer-heading">
            <img
              alt="/static/logo-full-200h.png"
              src="/static/logo-full-200h.png"
              className="footer-pasted-image"
            />
            <span className="footer-text">{props.text1}</span>
          </div>
          <div className="footer-socials">
            <a
              href="https://www.facebook.com/profile.php?id=61550637900232"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-link"
            >
              <Social
                Insider_src="/static/facebook-200w.png"
                rootClassName="social-root-class-name"
                className="footer-component"
              ></Social>
            </a>
            <a
              href="https://instagram.com/oshe_immo?igshid=NGlxbGttNG14N245"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-link1"
            >
              <Social
                Insider_src="/pastedimage-k0l6.svg"
                rootClassName="social-root-class-name1"
                className="footer-component1"
              ></Social>
            </a>
            <a
              href="https://www.tiktok.com/@osheimmo?lang=fr"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-link2"
            >
              <Social
                Insider_src="/static/tiktok-200h.png"
                rootClassName="social-root-class-name2"
                className="footer-component2"
              ></Social>
            </a>
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-column">
            <span className="footer-header">{props.Header1}</span>
            <a href="tel:0022879999998" className="footer-link3">
              {props.Link5}
            </a>
            <a
              href="mailto:osheimmo30@gmail.com?subject="
              className="footer-link4"
            >
              {props.Link6}
            </a>
          </div>
        </div>
      </div>
      <span className="footer-text1">{props.text}</span>
    </div>
  )
}

Footer.defaultProps = {
  Link9: 'Careers',
  Link1: 'Responsive Prototypes',
  Link: 'Responsive Web Design',
  Link61: 'Team',
  Link8: 'Partners',
  Link3: 'Static Website Builder',
  Link10: 'Press & Media',
  text1: 'Trouvez Votre Prochaine propriété avec Simplicité',
  Header1: 'Oshe Immo',
  pastedImage_src:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/4a46e3f6-a2fb-4002-a0ce-bb246bd8698d/99b28711-1d54-4589-9918-a73b8ad8556b?org_if_sml=15394',
  rootClassName: '',
  pastedImage_alt: 'pastedImage',
  Link51: 'About',
  Link7: 'News',
  Link2: 'Design to Code',
  Link5: '(00228) 79 99 99 98',
  Header11: 'Liens utils',
  text: '© 2022 Oshe Immo. Tous droits réservés.',
  Link91: 'Careers',
  Link101: 'Press & Media',
  Link81: 'Partners',
  Header: 'Solutions',
  Link71: 'News',
  Link6: 'osheimmo30@gmail.com',
  Link4: 'Static Website Generator',
}

Footer.propTypes = {
  Link9: PropTypes.string,
  Link1: PropTypes.string,
  Link: PropTypes.string,
  Link61: PropTypes.string,
  Link8: PropTypes.string,
  Link3: PropTypes.string,
  Link10: PropTypes.string,
  text1: PropTypes.string,
  Header1: PropTypes.string,
  pastedImage_src: PropTypes.string,
  rootClassName: PropTypes.string,
  pastedImage_alt: PropTypes.string,
  Link51: PropTypes.string,
  Link7: PropTypes.string,
  Link2: PropTypes.string,
  Link5: PropTypes.string,
  Header11: PropTypes.string,
  text: PropTypes.string,
  Link91: PropTypes.string,
  Link101: PropTypes.string,
  Link81: PropTypes.string,
  Header: PropTypes.string,
  Link71: PropTypes.string,
  Link6: PropTypes.string,
  Link4: PropTypes.string,
}

export default Footer
