import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './announcement.css'

const Announcement = (props) => {
  return (
    <div className={`announcement-announcement ${props.rootClassName} `}>
      <span className="announcement-title">{props.Title}</span>
      <div className="announcement-button">
        <Link to="/conditions" className="announcement-button-text">
          {props.Button}
        </Link>
      </div>
    </div>
  )
}

Announcement.defaultProps = {
  Title: 'Nous avons mis à jour nos conditions générales.',
  rootClassName: '',
  Button: 'Voir maintenant ->',
}

Announcement.propTypes = {
  Title: PropTypes.string,
  rootClassName: PropTypes.string,
  Button: PropTypes.string,
}

export default Announcement
