import React from 'react'

import {Helmet} from 'react-helmet'

import Heading from '../components/heading'
import Footer from '../components/footer'
import './contact.css'
import {Button, Stack, TextField, useMediaQuery, useTheme} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {enqueueSnackbar, SnackbarProvider} from "notistack";
import {LoadingButton} from '@mui/lab';
import {firestore} from "../config";

const Contact = (props) => {
    const theme = useTheme();

    const res = useMediaQuery(theme.breakpoints.down('md'))
    console.log(res)
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Email non valide').required(" L'email est requis "),
        name: Yup.string().required("Ce champs est requis "),
        subject: Yup.string().required("Ce champs est requis "),
        message: Yup.string().required("Ce champs est requis "),
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            name: "",
            email: "",
            subject: "",
            message: "",
        },
        onSubmit: async (values, formikHelpers) => {
            try {
                await firestore.collection("contact-us").add({...values, state: "En attente", createdAt: new Date()});

                enqueueSnackbar("Votre demande a été bien reçu", {variant: "success"})
            } catch (e) {
                enqueueSnackbar("Une erreur s'est produite.", {variant: "error"})
                console.log(e)
            }

        }
    })

    const {errors, values, touched, handleSubmit, setFieldValue, getFieldProps} = formik;

    const setFieldProps = (field) => {

        return {
            ...getFieldProps(field),
            error: Boolean(touched[field] && errors[field]),
            helperText: touched[field] && errors[field]
        }
    }

    return (
        <div className="contact-container">
            <Helmet>
                <title>Contact - Oshe Immo</title>
                <meta
                    name="description"
                    content="Trouvez Votre Prochaine propriété avec Simplicité"
                />
                <meta property="og:title" content="Contact - Oshe Immo"/>
                <meta
                    property="og:description"
                    content="Trouvez Votre Prochaine propriété avec Simplicité"
                />
            </Helmet>
            <Heading></Heading>
            <div className="contact-container1">
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                />
                <div className="contact-container2">
                    <div className="contact-container3"></div>
                    <img
                        alt="image"
                        src="/static/undraw_personal_file_re_5joy.svg"
                        className="contact-image"
                    />
                </div>
                <Stack width={{md:"50%",sm:"100%",}}>
                    <form className="contact-form">
                        <div className="contact-container4">
                            <span className="contact-text">Écrivez-nous</span>
                            <span className="contact-text1">   Nous répondons normalement dans les 2 jours ouvrables  </span>

                            <TextField
                                label={"Votre Nom"}
                                fullWidth
                                {...setFieldProps("name")}
                                className="contact-textinput input"
                                InputProps={{sx: {borderRadius: "8px", bgcolor: "#f6f7f8", borderWidth: 0}}}
                            />
                            <TextField
                                label={"Email"}
                                fullWidth
                                {...setFieldProps("email")}
                                className="contact-textinput input"
                                InputProps={{sx: {borderRadius: "8px", bgcolor: "#f6f7f8", borderWidth: 0}}}
                            />
                            <TextField
                                label={"Objet"}
                                fullWidth
                                {...setFieldProps("subject")}
                                className="contact-textinput input"
                                InputProps={{sx: {borderRadius: "8px", bgcolor: "#f6f7f8", borderWidth: 0}}}
                            />

                            <TextField
                                label={"Message"}
                                fullWidth
                                {...setFieldProps("message")}
                                className="contact-textinput input"
                                InputProps={{sx: {borderRadius: "8px", bgcolor: "#f6f7f8", borderWidth: 0}}}
                                multiline
                                minRows={5}
                            />

                            <LoadingButton loading={formik.isSubmitting} onClick={handleSubmit} variant={"contained"}
                                           className="contact-book-btn"
                                           sx={{bgcolor: "#000000"}}>
                                Envoyer
                            </LoadingButton>

                        </div>
                    </form>

                </Stack>
            </div>
            <Footer rootClassName="footer-root-class-name"></Footer>
        </div>
    )
}

export default Contact
