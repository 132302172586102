import React from 'react'

import { Helmet } from 'react-helmet'

import Heading from '../components/heading'
import Footer from '../components/footer'
import './policy.css'

const Policy = (props) => {
  return (
    <div className="policy-container">
      <Helmet>
        <title>Policy - Oshe Immo</title>
        <meta
          name="description"
          content="Trouvez Votre Prochaine propriété avec Simplicité"
        />
        <meta property="og:title" content="Policy - Oshe Immo" />
        <meta
          property="og:description"
          content="Trouvez Votre Prochaine propriété avec Simplicité"
        />
      </Helmet>
      <Heading></Heading>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default Policy
