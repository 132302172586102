import React from 'react'

import { Helmet } from 'react-helmet'

import Heading from '../components/heading'
import Footer from '../components/footer'
import './conditions.css'

const Conditions = (props) => {
  return (
    <div className="conditions-container">
      <Helmet>
        <title>Conditions - Oshe Immo</title>
        <meta
          name="description"
          content="Trouvez Votre Prochaine propriété avec Simplicité"
        />
        <meta property="og:title" content="Conditions - Oshe Immo" />
        <meta
          property="og:description"
          content="Trouvez Votre Prochaine propriété avec Simplicité"
        />
      </Helmet>
      <Heading></Heading>
      <iframe
        src="https://docs.google.com/document/d/e/2PACX-1vSuuF3B_i-dgmIMniNvxgktr4yozCPQRYDfrogwvYAyjRSzrljOlgehqpq8UTQXig5s6sCbo9R34dYd/pub?embedded=true"
        className="conditions-iframe"
      ></iframe>
      <Footer rootClassName="footer-root-class-name2"></Footer>
    </div>
  )
}

export default Conditions
