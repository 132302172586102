import React from 'react'

import PropTypes from 'prop-types'

import './highlight1.css'

const Highlight1 = (props) => {
  return (
    <div className="highlight1-highlight">
      <span className="highlight1-text">{props.Title}</span>
      <span className="highlight1-text1">{props.Description}</span>
    </div>
  )
}

Highlight1.defaultProps = {
  Title: 'Everything you get with Finbest',
  Description:
    'Sed do eiusmod tempor incididunt ut labore et dolore magna aliquat enim ad minim veniam, quis nostrud',
}

Highlight1.propTypes = {
  Title: PropTypes.string,
  Description: PropTypes.string,
}

export default Highlight1
