import React from 'react'
import { Link } from 'react-router-dom'

import Announcement from './announcement'
import Navbar from './navbar'
import './heading.css'

const Heading = (props) => {
  return (
    <div className="heading-heading">
      <div id="notifcation" className="heading-notification">
        <Link to="/">
          <Announcement
            rootClassName="announcement-root-class-name"
            className="heading-component"
          ></Announcement>
        </Link>
      </div>
      <Navbar></Navbar>
    </div>
  )
}

export default Heading
