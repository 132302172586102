import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBolANuf3hWDNwsQmY9v2FUYL0UxqW29mo",
    authDomain: "oshe-2c590.firebaseapp.com",
    databaseURL: "https://oshe-2c590-default-rtdb.firebaseio.com",
    projectId: "oshe-2c590",
    storageBucket: "oshe-2c590.appspot.com",
    messagingSenderId: "236249559904",
    appId: "1:236249559904:web:8e95b6a800ba1eac926405",
    measurementId: "G-KCM7956TJ6"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    firebase.firestore();
}

const firestore = firebase.firestore();

export {firebase,firestore};