import React from 'react'
import {Link} from 'react-router-dom'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
    const forceCloseMobileMenu = () => {
        const cursor=window.document.getElementById("mobile-menu");
        console.log("yeah ",cursor.style)
        cursor.style.transform=` translateX(100%)`
    }
    return (
        <nav className="navbar-navbar">
            <div className="navbar-desktop">
                <div className="navbar-main">
                    <div className="navbar-branding">
                        <Link to="/" className="navbar-navlink">
                            <img
                                alt="/static/logo-full-200h.png"
                                src="/static/logo-full-200h.png"
                                className="navbar-finbest"
                            />
                        </Link>
                    </div>
                    <div className="navbar-links">
                        <a href="/#discover" className="navbar-link link">
                            {props.Link_1}
                        </a>
                        <a href="/#app-features" className="navbar-link1 link">
                            {props.Link_2}
                        </a>
                        <Link to="/contact" className="link">
                            {props.Link_4}
                        </Link>
                    </div>
                </div>
                <div className="navbar-quick-actions">
                    <Link to="/" className="navbar-log-in link">
                        Log in
                    </Link>
                    <Link to="/" className="navbar-navlink1">
                        <div className="navbar-sign-up-btn">
                            <span className="navbar-sign-up">Sign up</span>
                        </div>
                    </Link>
                    <img
                        id="open-mobile-menu"
                        alt={props.pastedImage_alt}
                        src={props.pastedImage_src}
                        className="navbar-hamburger-menu"
                    />
                </div>
            </div>
            <div id="mobile-menu" className="navbar-mobile">
                <div className="navbar-top">
                    <img
                        alt={props.image_alt}
                        src="/static/logo-full-200h.png"
                        className="navbar-image"
                    />
                    <svg
                        id="close-mobile-menu"
                        viewBox="0 0 1024 1024"
                        className="navbar-icon"
                    >
                        <path
                            d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                    </svg>
                </div>
                <div className="navbar-links1">
                    <a href="/#discover" className="navbar-link3" onClick={forceCloseMobileMenu}>
                        {props.text1}
                    </a>
                    <a href="/#app-features" className="navbar-link4" onClick={forceCloseMobileMenu}>
                        {props.text11}
                    </a>
                    <Link to="/contact" className="navbar-navlink2" onClick={forceCloseMobileMenu}>
                        {props.text13}
                    </Link>
                    <div className="navbar-buttons">
                        <Link to="/" className="navbar-navlink3">
                            <div className="navbar-btn">
                                <span className="navbar-text">{props.text131}</span>
                            </div>
                        </Link>
                        <Link to="/" className="navbar-navlink4">
                            <div className="navbar-btn1">
                                <span className="navbar-text1">{props.text1311}</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div>
                <div className="navbar-container1">
                    <Script
                        html={` <script>
    /*
    Mobile menu - Code Embed
    */

    // Mobile menu
    const mobileMenu = document.querySelector("#mobile-menu");

    // Buttons
    const closeButton = document.querySelector("#close-mobile-menu");
    const openButton = document.querySelector("#open-mobile-menu");

    if (mobileMenu && closeButton && openButton) {
      // On openButton click, set the mobileMenu position left to -100vw
      openButton.addEventListener("click", function () {
        mobileMenu.style.transform = "translateX(0%)";
      });

      // On closeButton click, set the mobileMenu position to 0vw
      closeButton.addEventListener("click", function () {
        mobileMenu.style.transform = "translateX(100%)";
      });
    }
  </script>`}
                    ></Script>
                </div>
            </div>
        </nav>
    )
}

Navbar.defaultProps = {
    Link_3: 'Qui nous sommes',
    text1: 'Qui nous sommes',
    text12: 'Prices',
    Link_4: 'Contact\n',
    Branding_src:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/4a46e3f6-a2fb-4002-a0ce-bb246bd8698d/afed3d97-066a-440a-a27e-491fdc7ac2b5?org_if_sml=15413',
    Link_1: 'Qui nous sommes',
    text131: 'Log in',
    image_alt: 'image',
    pastedImage_src: '/pastedimage-8o98.svg',
    text1311: 'Sign up',
    text13: 'Contact',
    Link_2: 'Notre Application',
    pastedImage_alt: 'pastedImage',
    image_src: 'afed3d97-066a-440a-a27e-491fdc7ac2b5',
    Branding_alt: 'pastedImage',
    text11: 'Notre Application',
}

Navbar.propTypes = {
    Link_3: PropTypes.string,
    text1: PropTypes.string,
    text12: PropTypes.string,
    Link_4: PropTypes.string,
    Branding_src: PropTypes.string,
    Link_1: PropTypes.string,
    text131: PropTypes.string,
    image_alt: PropTypes.string,
    pastedImage_src: PropTypes.string,
    text1311: PropTypes.string,
    text13: PropTypes.string,
    Link_2: PropTypes.string,
    pastedImage_alt: PropTypes.string,
    image_src: PropTypes.string,
    Branding_alt: PropTypes.string,
    text11: PropTypes.string,
}

export default Navbar
